import React from 'react'
import { Link, graphql } from 'gatsby'
import { RichText } from 'prismic-reactjs'
import styled from 'styled-components'
import LazyLoad from 'react-lazyload'

import SEO from '../components/seo'
import Layout from '../components/layout'

const SinglePost = styled.div`
	background: url('${(props) => props.bgImage}') no-repeat center center;
	animation: fadein 600ms ease-out;
`

const Homepage = ({ data }) => {
	const posts = data.prismic.allPosts.edges

	return (
		<Layout>
			<SEO title={`Nico, photographer based in Zurich`} />
			<div className="blogPosts">
				{posts.map((post) => {
					return (
						<LazyLoad height={200} once={true} key={post.node._meta.id}>
							<Link to={`/${post.node._meta.uid}`} title={RichText.asText(post.node.page_title)}>
								<SinglePost bgImage={post.node.cover_image.url}>
									{RichText.asText(post.node.page_title)}
								</SinglePost>
							</Link>
						</LazyLoad>
					)
				})}
			</div>
		</Layout>
	)
}

export default Homepage

export const postList = graphql`
	{
		prismic {
			allPosts(sortBy: date_DESC) {
				edges {
					node {
						_meta {
							id
							uid
						}
						page_title
						cover_image
						date
					}
				}
			}
		}
	}
`
